function shouldHaveACode (to, from, next) {
  if (to.query.code) {
    next()
  } else {
    next({ name: 'Default' })
  }
}

export default [
  {
    path: '/authentication',
    component: () => import(/* webpackChunkName: "authentication" */ './views/AuthenticationLayout.vue'),
    children: [
      {
        path: 'callback',
        name: 'AuthenticationCallback',
        meta: {
          title: 'nptb_authentication_callback_title',
          hasHeader: false,
          hasNavigation: false,
          hasToBeAuthenticated: false
        },
        component: () => import(/* webpackChunkName: "authentication" */ './views/AuthenticationCallback.vue'),
        beforeEnter: shouldHaveACode
      },
      {
        path: 'log-out',
        name: 'AuthenticationLogOut',
        meta: {
          title: 'nptb_authentication_logOut_title',
          hasHeader: false,
          hasNavigation: false,
          hasToBeAuthenticated: true
        },
        component: () => import(/* webpackChunkName: "authentication" */ './views/AuthenticationLogOut.vue')
      }
    ]
  }
]
