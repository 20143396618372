import { createI18n } from 'vue-i18n'

const numberFormats = {
  'en': {
    currency: { style: 'currency', currency: 'EUR', notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 },
    decimal: { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 },
    percent: { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }
  },
  'fr': {
    currency: { style: 'currency', currency: 'EUR', notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 },
    decimal: { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 },
    percent: { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }
  }
}

const datetimeFormats = {
  'en': {
    onlyDay: { day: 'numeric' },
    onlyWeekDay: { weekday: 'short' },
    onlyWeekDayLong: { weekday: 'long' },
    onlyMonth: { month: 'long' },
    onlyMonthAndYear: { month: 'long', year: 'numeric' },
    onlyYear: { year: 'numeric' },
    shortDay: { day: 'numeric', weekday: 'short' },
    shortMonth: { month: 'short', day: 'numeric' },
    shortDayAndMonth: { month: 'short', day: 'numeric', weekday: 'short' },
    long: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short' },
    longWithoutYear: { month: 'short', day: 'numeric', weekday: 'short' }
  },
  'fr': {
    onlyDay: { day: 'numeric' },
    onlyWeekDay: { weekday: 'short' },
    onlyWeekDayLong: { weekday: 'long' },
    onlyMonth: { month: 'long' },
    onlyYear: { year: 'numeric' },
    onlyMonthAndYear: { month: 'long', year: 'numeric' },
    shortDay: { day: 'numeric', weekday: 'short' },
    shortMonth: { month: 'short', day: 'numeric' },
    shortDayAndMonth: { month: 'short', day: 'numeric', weekday: 'short' },
    long: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' },
    longWithoutYear: { month: 'short', day: 'numeric', weekday: 'short' }
  }
}

/**
 * Load the locale files defined in the locales directory.
 * @private
 * @returns {object}
 */
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[a-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })

  return messages
}

export default createI18n({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  numberFormats,
  datetimeFormats,
  messages: loadLocaleMessages()
})
