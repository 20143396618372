<template>
  <div class="nptb-application-logo">
    <h6 class="nptb-application-logo-primary text-h6">
      <span v-if="mobile">{{ t('nptb_application_shortTitle') }}</span>
      <span v-else>{{ t('nptb_application_title') }}</span>
    </h6>
    <v-slide-x-transition>
      <div class="nptb-application-logo-secondary ml-1 text-caption text-grey-darken-1"
            v-if="isSecondaryDisplayed && !mobile">
        <span class="mt-1">{{ t('nptb_application_by') }}</span>
        <img alt="Company logo of Adeo"
             class="ml-1"
             :src="logo" />
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

import adeoLogo from '@/assets/logo_adeo.png'

const { t } = useI18n()

const { mobile } = useDisplay()

const isSecondaryDisplayed = ref(false)
const logo = ref(adeoLogo)

onMounted(() => {
  setTimeout(
    () => isSecondaryDisplayed.value = true,
    1000
  )
})
</script>

<style scoped lang="sass">
.nptb-application-logo
  display: flex
  align-items: center

  &-secondary
    display: flex
    align-items: center

    img
      height: 20px
      max-width: 100%
</style>
