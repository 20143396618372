export default {
  "nptb_application_shortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPTB"])},
  "nptb_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Product To Buy"])},
  "nptb_application_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par"])},
  "nptb_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeo"])},
  "nptb_application_noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée"])},
  "nptb_application_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "nptb_application_menu_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "nptb_application_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "nptb_form_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
  "nptb_campaign_status_todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmé"])},
  "nptb_campaign_status_doing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "nptb_campaign_status_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "nptb_authenticationCallback_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])},
  "nptb_authenticationCallback_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "nptb_authenticationCallback_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas réussi à vous authentifier."])},
  "nptb_authenticationCallback_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_application_retry", undefined, _type)])},
  "nptb_authenticationLogOut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "nptb_authenticationLogOut_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "nptb_authenticationLogOut_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas réussi à vous déconnecter."])},
  "nptb_authenticationLogOut_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_application_retry", undefined, _type)])},
  "nptb_userPreferenceMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes préférences"])},
  "nptb_userPreferenceMenu_density_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Densité d'affichage"])},
  "nptb_userPreferenceMenu_density_default_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut"])},
  "nptb_userPreferenceMenu_density_compact_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact"])},
  "nptb_userPreferenceMenu_theme_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème"])},
  "nptb_userPreferenceMenu_theme_default_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut"])},
  "nptb_userPreferenceMenu_theme_light_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair"])},
  "nptb_userPreferenceMenu_theme_dark_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombre"])},
  "nptb_userPreferenceMenu_lang_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "nptb_userPreferenceMenu_lang_fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "nptb_userPreferenceMenu_lang_en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "nptb_userProfileMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
  "nptb_userProfileMenu_displayName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("value"))])},
  "nptb_userProfileMenu_logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "nptb_alertMenu_alerts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune notification"]), _normalize([_interpolate(_named("count")), " notification"]), _normalize([_interpolate(_named("count")), " notifications"])])},
  "nptb_alertMenu_createdAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Créée le ", _interpolate(_named("date"))])},
  "nptb_feedbackMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide & commentaire"])},
  "nptb_feedbackMenu_feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner un feedback"])},
  "nptb_feedbackMenu_feedback_sublabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Tangram"])},
  "nptb_feedbackMenu_bug_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer un bug"])},
  "nptb_feedbackMenu_bug_sublabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Service Now"])},
  "nptb_calendarMonthly_weekNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S.", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes"])},
  "nptb_campaignCalendar_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle campagne"])},
  "nptb_campaignCalendar_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "nptb_campaignCalendar_temporality_select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passer à ", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_mode_month_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "nptb_campaignCalendar_mode_week_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
  "nptb_campaignCalendar_weekNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Semaine ", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_week": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du ", _interpolate(_named("startDate")), " au ", _interpolate(_named("endDate"))])},
  "nptb_campaignNew_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une campagne"])},
  "nptb_campaignNew_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "nptb_campaignNew_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "nptb_campaignNew_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "nptb_campaignNew_name_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_startDate_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "nptb_campaignNew_startDate_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_startDate_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide, doit être une date à min. J+5 et max. A+1"])},
  "nptb_campaignNew_targetsSize_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de clients atteignables"])},
  "nptb_campaignNew_targetsSize_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_targetsSize_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide, doit être un nombre à min. 1"])},
  "nptb_campaignNew_targetsSize_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluant ceux qui ont acheté sur les 10 dernières semaines"])},
  "nptb_campaignNew_targetsIncluded_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
  "nptb_campaignNew_targetsIncluded_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_targetsIncluded_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide, doit être inférieur ou égale à 10 sélections"])},
  "nptb_campaignNew_targetsExcluded_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "nptb_campaignNew_targetsExcluded_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide, doit être inférieur ou égale à 10 sélections"])},
  "nptb_nomenclature_select_placeholder": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rechercher dans la nomenclature"]), _normalize(["Continuer ?"]), _normalize(["Continuer ?"])])},
  "nptb_calendarDaily_quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quantité : ", _interpolate(_named("value"))])},
  "nptb_calendarDaily_offers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " offre :"]), _normalize([_interpolate(_named("count")), " offres :"])])},
  "nptb_calendarDaily_filters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sans filtre"]), _normalize([_interpolate(_named("count")), " filtre :"]), _normalize([_interpolate(_named("count")), " filtres :"])])},
  "nptb_calendarDaily_campaigns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune campagne planifiée"]), _normalize([_interpolate(_named("count")), " campagne planifiée"]), _normalize([_interpolate(_named("count")), " campagnes planifiées"])])},
  "nptb_calendarDaily_todo": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_todo", undefined, _type)])},
  "nptb_campaignDaily_doing": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_doing", undefined, _type)])},
  "nptb_campaignDaily_done": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_done", undefined, _type)])},
  "nptb_campaignCalendar_legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Légende"])},
  "nptb_campaignCalendar_todo": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_todo", undefined, _type)])},
  "nptb_campaignCalendar_doing": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_doing", undefined, _type)])},
  "nptb_campaignCalendar_done": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_done", undefined, _type)])},
  "nptb_campaignCalendar_nextCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaines campagnes"])},
  "nptb_campaignCalendar_noNextCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de campagnes créées pour cette semaine"])}
}