import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * Find departments.
 * @param {number} buId
 * @return {Promise<Array>}
 */
export function findNomenclatureDepartments (buId) {

    return httpsCallable(
            getFunctions(getApp(), 'europe-west1'), 
            'nptb_c_getNomenclatureDepartments')({ buId })
        .then(response => response.data)

}

/**
 * Find sub departments.
 * @param {number} buId
 * @param {number} departmentId
 * @return {Promise<Array>}
 */
export function findNomenclatureSubDepartments (buId, departmentId) {

    return httpsCallable(
            getFunctions(getApp(), 'europe-west1'), 
            'nptb_c_getNomenclatureSubDepartments')({ buId, departmentId })
        .then(response => response.data)

}

/**
 * Find types.
 * @param {number} buId
 * @param {number} departmentId
 * @param {number} subDepartmentId
 * @return {Promise<Array>}
 */
export function findNomenclatureTypes (buId, departmentId, subDepartmentId) {

    return httpsCallable(
            getFunctions(getApp(), 'europe-west1'), 
            'nptb_c_getNomenclatureTypes')({ buId, departmentId, subDepartmentId })
        .then(response => response.data)

}