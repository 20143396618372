<template>
  <v-app-bar app
             class="app-header"
             id="app-header">
    <v-toolbar-title>
      <NptbApplicationLogo v-once />
    </v-toolbar-title>

    <template #append>
      <AlertMenu :isDisabled="isLoadingUserProfile"
                 :buId="selectedBuId" />
      <FeedbackMenu />
      <UserPreferenceMenu class="mr-1"
                          :isDisabled="isLoadingUserPreference"
                          :density="density"
                          :theme="theme"
                          :lang="lang"
                          @update:density="persistDensity"
                          @update:theme="persistTheme"
                          @update:lang="persistLang" />
      <UserProfileMenu :isDisabled="isLoadingUserProfile"
                       :density="density"
                       :user="user"
                       :selectedBuId="selectedBuId" />
    </template>
  </v-app-bar>
</template>

<script setup>
import { onMounted, watch, ref, computed } from 'vue'
import { useStore } from 'vuex'

import { reportError } from './registerErrorReporting'
import { logEventInAnalytics } from './registerAnalytics'

import NptbApplicationLogo from '@/components/NptbApplicationLogo'

import { findUserPreference, updateUserPreference } from '@/features/user/services/repositories/userPreferenceRepository'
import { findUserProfile } from '@/features/user/services/repositories/userProfileRepository'
import { getAuthenticatedUser } from '@/features/authentication/services/authenticationService'

import UserProfileMenu from '@/features/user/components/UserProfileMenu'
import UserPreferenceMenu from '@/features/user/components/UserPreferenceMenu'

import AlertMenu from '@/features/alert/components/AlertMenu'

import FeedbackMenu from '@/features/feedback/components/FeedbackMenu'

const { dispatch } = useStore()

const userAuthenticated = ref(undefined)
const userProfile = ref(undefined)
const userPreference = ref(undefined)

const user = computed(() => {
  if (userAuthenticated.value && userProfile.value) {
    return {
      displayName: userAuthenticated.value.displayName,
      email: userAuthenticated.value.email,
      ldap: userAuthenticated.value.uid,
      buCode: userProfile.value.buCode
    }
  }

  return undefined
})
const selectedBuId = computed(() => userProfile.value?.selectedBuId)

const density = computed(() => userPreference.value?.density)
const theme = computed(() => userPreference.value?.theme)
const lang = computed(() => userPreference.value?.lang)

const isLoadingUserProfile = ref(true)
const isLoadingUserPreference = ref(true)


async function getUserProfile () {
  isLoadingUserProfile.value = true

  try {
    userAuthenticated.value = getAuthenticatedUser()
    userProfile.value = await findUserProfile()
    dispatch('user/setProfile', userProfile.value)
  } catch (error) {
    reportError(error)
  } finally {
    isLoadingUserProfile.value = false
  }
}

async function getUserPreference () {
  isLoadingUserPreference.value = true

  try {
    userPreference.value = await findUserPreference()
  } catch (error) {
    reportError(error)
  } finally {
    isLoadingUserPreference.value = false
  }
}

function persistDensity (density) {
  userPreference.value.density = density
  updateUserPreference({ density })
  logEventInAnalytics('nptb-userPreference-density-used', { density })
}

function persistTheme (theme) {
  userPreference.value.theme = theme
  updateUserPreference({ theme })
  logEventInAnalytics('nptb-userPreference-theme-used', { theme })
}

function persistLang (lang) {
  userPreference.value.lang = lang
  updateUserPreference({ lang })
  logEventInAnalytics('nptb-userPreference-lang-used', { lang })
}

onMounted(() => {
  getUserProfile()
  getUserPreference()
})

watch([density, theme, lang], () => {
  dispatch('app/setDensity', density)
  dispatch('app/setTheme', theme)
  dispatch('app/setLang', lang)
})
</script>
