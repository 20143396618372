<template>
  <div class="nptb-title">
    <v-btn class="mr-2"
           icon
           :to="props.backTo"
           variant="plain"
           v-if="props.backTo">
        <v-icon>{{ icons.back }}</v-icon>
    </v-btn>

    <h5 class="nptb-title-text text-h5 mb-6">
      <slot />
      <v-progress-linear class="nptb-title-text-line mt-4"
                         color="primary"
                         :indeterminate="props.isLoading"
                         :modelValue="100" />
    </h5>
  </div>
</template>
  
<script setup>
import { defineProps, ref } from 'vue'
import { mdiArrowLeft } from '@mdi/js'
  
const props = defineProps({
  backTo: {
    type: [Object, String]
  },
  isLoading: {
    type: Boolean,
    default: false
  }
})

const icons = ref ({
    back: mdiArrowLeft
})
</script>
  
<style scoped lang="sass">
.nptb-title
  display: flex

  &-text
    &-line
      left: 0% !important
      transform: none !important
      width: 4.5rem  
</style>