<template>
  <div class="campaign-calendar"
       id="campaign-calendar">
    <v-toolbar tag="div"
                color="transparent">
      <v-btn color="primary"
             :icon="mobile"
             variant="plain"
             @click="applyTemporality('today')">
        <v-icon v-if="mobile">{{ icons.today }}</v-icon>
        <span v-else>{{ t('nptb_campaignCalendar_today') }}</span>
      </v-btn>
      <v-btn icon
             variant="flat"
             @click="applyTemporality('previous')">
        <v-tooltip :text="temporalityTexts.previousTitle"
                   location="bottom">
          <template #activator="{ props }">
            <v-icon v-bind="props">{{ icons.previous }}</v-icon>
          </template>
        </v-tooltip>
      </v-btn>
      <v-btn icon
             variant="flat"
             @click="applyTemporality('next')">
        <v-tooltip :text="temporalityTexts.nextTitle"
                   location="bottom">
          <template #activator="{ props }">
            <v-icon v-bind="props">{{ icons.next }}</v-icon>
          </template>
        </v-tooltip>  
      </v-btn>

      <v-list-item lines="two">
        <template #title>
          <span class="text-capitalize">{{ temporalityTexts.title }}</span>
        </template>
        <template #subtitle>{{ temporalityTexts.subtitle }}</template>
      </v-list-item>

      <template #append>

        <v-snackbar location="top right"
                    timeout="2000"
                    v-model="isSnackBarOpened">
          {{ t('nptb_campaignCalendar_noNextCampaigns') }}
        </v-snackbar>
        
        <v-btn color="primary"
             :icon="mobile"
             variant="plain"
             @click="goToNextCampaigns()">
          <v-icon v-if="mobile">{{ icons.nextCampaigns }}</v-icon>
          <span v-else>{{ t('nptb_campaignCalendar_nextCampaigns') }}</span>
        </v-btn>

        <v-tooltip location="bottom"
                   :text="t('nptb_campaignCalendar_legend')">
            <template #activator="{ props }">
                <v-btn class="mr-2"
                       flat
                       icon
                       id="caption-menu-activator"
                       v-bind="props">
                  <v-icon>{{ icons.help }}</v-icon>
              </v-btn>
            </template>
        </v-tooltip>

        <v-menu activator="#caption-menu-activator"
                :close-on-content-click="false"
                density="compact"
                width="300px">
          <v-list>
            <v-list-subheader>{{ t('nptb_campaignCalendar_legend') }}</v-list-subheader>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon :color="colors.TODO">{{ icons.todo }}</v-icon>
                </v-avatar>
              </template>
              <template #title>{{ t('nptb_campaignCalendar_todo') }}</template>
            </v-list-item>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon :color="colors.DOING">{{ icons.doing }}</v-icon>
                </v-avatar>
              </template>
              <template #title>{{ t('nptb_campaignCalendar_doing') }}</template>
            </v-list-item>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon :color="colors.DONE">{{ icons.done }}</v-icon>
                </v-avatar>
              </template>
              <template #title>{{ t('nptb_campaignCalendar_done') }}</template>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-select density="compact"
                  hide-details
                  :items="modes"
                  :modelValue="mode"
                  variant="outlined"
                  @update:modelValue="applyMode"
                  v-if="!mobile">
          <template #selection="{ item }">
            {{ item.title }}
          </template>
        </v-select>
      </template>
    </v-toolbar>
    
    <v-expand-transition>
      <NptbCalendarMonthly :date="date"
                           :minDate="minDate"
                           :maxDate="maxDate"
                           :modelValue="campaigns"
                           @click:new:event="goToNew"
                           @update:rangeDates="updateRangeDates"
                           @delete:event="deleteCampaign"
                           v-if="mode === 'month'" />
    </v-expand-transition>

    <v-expand-transition>
      <NptbCalendarWeekly :date="date"
                          :minDate="minDate"
                          :maxDate="maxDate"
                          :modelValue="campaigns"
                          @click:new:event="goToNew"
                          @update:rangeDates="updateRangeDates"
                          @delete:event="deleteCampaign"
                          v-if="mode === 'week'" />
    </v-expand-transition>

    <v-btn :aria-label="t('nptb_campaignCalendar_new')"
           class="campaign-calendar-new-btn"
           color="primary"
           icon
           size="x-large"
           @click="goToNew()">
      <v-icon>{{ icons.new }}</v-icon>
    </v-btn>
  </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
import { mdiCalendarTodayOutline, mdiCheckCircleOutline, mdiChevronLeft, mdiChevronRight, mdiHelpCircleOutline, mdiPageNextOutline, mdiPlus, mdiProgressClock, mdiProgressHelper } from '@mdi/js'
import { DateTime } from 'luxon'
import { useStore } from 'vuex'

import { reportError } from '@/registerErrorReporting'

import { getCampaigns, getNextCampaignDate, deleteCampaignById } from '../services/repositories/campaignRepository'

import NptbCalendarMonthly from '@/components/NptbCalendarMonthly'
import NptbCalendarWeekly from '@/components/NptbCalendarWeekly'

const route = useRoute()
const router = useRouter()

const { d, t } = useI18n()

const { mobile } = useDisplay()

const store = useStore()

const DEFAULT_DAY_FORMAT = 'yyyy-LL-dd'

const icons = ref({
  previous: mdiChevronLeft,
  next: mdiChevronRight,
  new: mdiPlus,
  today: mdiCalendarTodayOutline,
  help: mdiHelpCircleOutline,
  todo: mdiProgressClock,
  doing: mdiProgressHelper,
  done: mdiCheckCircleOutline,
  nextCampaigns : mdiPageNextOutline
})

const colors = ref({
  TODO: 'grey',
  DOING: 'primary',
  DONE: 'success'
})

const rangeDates = ref([])

const campaigns = ref([])

const userProfile = computed(() => store.state.user.profile)

const isSnackBarOpened = ref(false)

let timeoutId = undefined

const mode = computed(() => {
  if(mobile.value) {
    return 'week'
  }

  if (route.query?.mode
      && ['month', 'week'].includes(route.query?.mode)) {
    return route.query.mode
  }

  return 'month'
})
const modes = computed(() => {
  return [
    {
      title: t('nptb_campaignCalendar_mode_month_select'),
      value: 'month'
    },
    {
      title: t('nptb_campaignCalendar_mode_week_select'),
      value: 'week'
    }
  ]
})

const date = computed(() => route.query?.date && DateTime.fromFormat(route.query.date, DEFAULT_DAY_FORMAT).isValid ? DateTime.fromFormat(route.query.date, DEFAULT_DAY_FORMAT).toJSDate() : DateTime.local().toJSDate())
const minDate = ref(DateTime.local().plus({ days: 5 }).toJSDate())
const maxDate = ref(DateTime.local().plus({ year: 1 }).toJSDate())
const previousDate = computed(() => DateTime.fromJSDate(date.value).minus({ [mode.value]: 1 }).startOf(mode.value).toJSDate())
const nextDate = computed(() => DateTime.fromJSDate(date.value).plus({ [mode.value]: 1 }).startOf(mode.value).toJSDate())

const temporalityTexts = computed(() => {
  const texts = {
    previousTitle: undefined,
    nextTitle: undefined,
    title: undefined,
    subtitle: undefined
  }

  if (mode.value === 'month') {
    texts.previousTitle = t('nptb_campaignCalendar_temporality_select', { value: d(previousDate.value, 'onlyMonth') })
    texts.nextTitle = t('nptb_campaignCalendar_temporality_select', { value: d(nextDate.value, 'onlyMonth') })
    texts.title = d(date.value, 'onlyMonth')
    texts.subtitle = d(date.value, 'onlyYear')
  }

  if (mode.value === 'week') {
    const startDate = DateTime.fromJSDate(date.value).startOf('week').toJSDate()
    const endDate = DateTime.fromJSDate(date.value).endOf('week').toJSDate()
    
    texts.previousTitle = t('nptb_campaignCalendar_temporality_select', { value: t('nptb_campaignCalendar_weekNumber', { value: DateTime.fromJSDate(previousDate.value).weekNumber }) })
    texts.nextTitle = t('nptb_campaignCalendar_temporality_select', { value: t('nptb_campaignCalendar_weekNumber', { value: DateTime.fromJSDate(nextDate.value).weekNumber }) })
    texts.title = t('nptb_campaignCalendar_weekNumber', { value: DateTime.fromJSDate(date.value).weekNumber })
    texts.subtitle = t('nptb_campaignCalendar_week', { startDate: d(startDate, 'shortMonth'), endDate: d(endDate, 'shortMonth') })
  }
  
  return texts
})


function applyTemporality (temporality) {
  // eslint-disable-next-line
  const { ['date']: _, ...query } = route.query

  if (temporality === 'today') {
    router.push({ name: 'CampaignCalendar', query })
  } else if(temporality === 'previous') {
    router.push({ name: 'CampaignCalendar', query: { date: DateTime.fromJSDate(previousDate.value).toFormat(DEFAULT_DAY_FORMAT), ...query } })
  } else {
    router.push({ name: 'CampaignCalendar', query: { date: DateTime.fromJSDate(nextDate.value).toFormat(DEFAULT_DAY_FORMAT), ...query } })
  }
}

function applyMode (mode) {
  // eslint-disable-next-line
  const { ['mode']: _, ...query } = route.query
  router.push({ name: 'CampaignCalendar', query: { mode, ...query } })
}

function goToNew (date) {
  if (!date) {
    router.push({ name: 'CampaignNew' })
  }

  let dateTime = DateTime.fromJSDate(date)
  
  if(dateTime > DateTime.local().plus({ days: 5 })) {
    const query = { from: encodeURIComponent(`${window.location.pathname}${window.location.search}`) }

    if (date) {
      query.date = dateTime.toFormat(DEFAULT_DAY_FORMAT)
    }

    router.push({ name: 'CampaignNew', query })
  }
}

async function goToNextCampaigns() {
  let res = await getNextCampaignDate()
  let nextDate = res[0]?.startDate?.value
  if(nextDate) {
    router.push({ name: 'CampaignCalendar', query: { mode: 'week', date: nextDate } })
  } else {
    isSnackBarOpened.value = true
  }
}

function updateRangeDates (dates) {
  rangeDates.value = dates
}

async function findCampaigns() {
  store.dispatch('app/load')

  try {
    campaigns.value = await getCampaigns(DateTime.fromJSDate(rangeDates.value[0]).toFormat(DEFAULT_DAY_FORMAT), DateTime.fromJSDate(rangeDates.value[1]).toFormat(DEFAULT_DAY_FORMAT))
  } catch (error) {
    reportError(new Error(`findCampaigns-0001 : failed to find campaigns due to : "${error.message}".`))
  } finally {
    store.dispatch('app/loaded')
  }
}

async function deleteCampaign(id) {
  store.dispatch('app/load')

  try {
    await deleteCampaignById(id)
    await findCampaigns()
  } catch (error) {
    reportError(new Error(`deleteCampaign-0001 : failed to delete campaign due to : "${error.message}".`))
  } finally {
    store.dispatch('app/loaded')
  }
}

watch (rangeDates, () => {
  if(timeoutId) {
    clearTimeout(timeoutId)
  }

  timeoutId = setTimeout(
    () => {
      findCampaigns()
    }, 
    '300')
})

watch(userProfile, () => {
  findCampaigns()
})

</script>

<style scoped lang="sass">
.campaign-calendar
  height: calc(100% - 64px)

  &-new-btn
    position: fixed
    bottom: 30px
    right: 30px
</style>
