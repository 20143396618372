<template>
  <v-app class="app">
    <AppHeader v-if="hasHeader" />
    <AppMain />
  </v-app>
</template>

<script setup>
import { watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useTheme } from 'vuetify'

import i18n from './registerI18n'

import AppHeader from '@/AppHeader'
import AppMain from '@/AppMain'

const route = useRoute()

const { state } = useStore()

const theme = useTheme()
const hasHeader = computed(() => route.meta.hasHeader)

const browserTheme = window.matchMedia("(prefers-color-scheme:dark)")

function applyTheme (value) {
  if (value) {
    theme.global.name.value = value
  } else {
    theme.global.name.value = browserTheme.matches ? 'dark' : 'light'
  }
}

watch(() => state.app.lang, (value) => {
  i18n.global.locale.value = value
})

watch(() => state.app.theme, (value) => {
  if(value === 'default') {
    applyTheme()
    browserTheme.addEventListener('change', applyTheme, true)
  } else {
    applyTheme(value)
    browserTheme.removeEventListener('change', applyTheme, true)
  }
})
</script>
