import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { en, fr } from 'vuetify/locale'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    defaultLocale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    messages: { en, fr }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
            primary: '#00919f',
            'primary-lighten-1': '#d9f0f3',
            'primary-lighten-2': '#91d5db',
            'primary-lighten-3': '#48bac4',
            'primary-lighten-4': '#009eac',
            'primary-darken-1': '#007f8c',
            'primary-darken-2': '#006974',
            'primary-darken-3': '#004e57',
            'primary-darken-4': '#002e33',
            secondary: '#605f9d',
            'secondary-lighten-1': '#e7e7f0',
            'secondary-lighten-2': '#c5c5dc',
            'secondary-lighten-3': '#a4a3c7',
            'secondary-lighten-4': '#8281b2',
            'secondary-darken-1': '#4b4a8c',
            'secondary-darken-2': '#393879',
            'secondary-darken-3': '#282863',
            'secondary-darken-4': '#1a1a4b',
            grey: '#8c8b85',
            'grey-lighten-1': '#eeedea',
            'grey-lighten-2': '#dddcd5',
            'grey-lighten-3': '#c2c1ba',
            'grey-lighten-4': '#a7a6a0',
            'grey-darken-1': '#71706b',
            'grey-darken-2': '#555550',
            'grey-darken-3': '#3a3936',
            'grey-darken-4': '#1e1e1c',
            error: '#ea302d',
            info: '#0b96cc',
            success: '#46a610',
            warning: '#ea7315',
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: '#03DAC5'
        }
      },
    },
  }
})
