<template>
  <v-container class="calendar-weekly pt-0"
               fluid>
    <v-row>
      <v-col cols="12"
            md="8"
            offset-md="2"
            lg="6"
            offset-lg="3">
        <NptbCalendarDaily :key="`day_${index}`"
                            :modelValue="day"
                            @delete:event="emitDelete"
                            @click:new:event="emitNewEvent(day.value)"
                            v-for="(day, index) in days" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineProps, defineEmits, watch, computed, onMounted } from 'vue'
import { DateTime, Interval } from 'luxon'

import NptbCalendarDaily from './NptbCalendarDaily'

const props = defineProps({
  withoutWeekends: {
    type: Boolean,
    default: false
  },
  date: {
    type: Date,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => ([])
  },
  minDate: {
    type: Date
  },
  maxDate: {
    type: Date
  }
})

const emits = defineEmits(['update:rangeDates', 'delete:event', 'click:new:event'])

const startDate = computed(() => DateTime.fromJSDate(props.date).startOf('week'))
const endDate = computed(() => DateTime.fromJSDate(props.date).endOf('week'))

const DEFAULT_DAY_FORMAT = 'yyyy-LL-dd'

const days = computed(() => Interval.fromDateTimes(startDate.value, endDate.value)
                                    .splitBy({ day: 1 })
                                    .map(dt => {
                                      const isActualMonth = DateTime.fromJSDate(props.date).month === dt.start.month
                                      const isToday = DateTime.local().hasSame(dt.start, 'day') 
                                                      && DateTime.local().hasSame(dt.start, 'month')
                                                      && DateTime.local().hasSame(dt.start, 'year')
                                      const value = dt.start.toJSDate()
                                      const events = props.modelValue.filter(event => {
                                        const eventDateTime = DateTime.fromFormat(event.startDate.value, DEFAULT_DAY_FORMAT)
                                        return dt.start.hasSame(eventDateTime, 'day')
                                            && dt.start.hasSame(eventDateTime, 'month')
                                            && dt.start.hasSame(eventDateTime, 'year')
                                      })

                                      let isDisabled = false

                                      if (props.minDate) {
                                        isDisabled = dt.start < DateTime.fromJSDate(props.minDate)
                                      }

                                      if (!isDisabled && props.maxDate) {
                                        isDisabled = dt.start > DateTime.fromJSDate(props.maxDate)
                                      }
                                      
                                      return {
                                        value,
                                        isActualMonth,
                                        isToday,
                                        isDisabled,
                                        events
                                      }
                                    }))


function emitNewEvent (day) {
  emits('click:new:event', day)
}

function emitDelete (id) {
  emits('delete:event', id)
}

watch(
  [ startDate, endDate ],
  (rangeDates) => {
    if (rangeDates[0] && rangeDates[1]) {
      emits('update:rangeDates', rangeDates.map(rd => rd.toJSDate()))
    }
  })

onMounted(() => {
  emits('update:rangeDates', [startDate.value, endDate.value].map(rd => rd.toJSDate()))
})
</script>

<style scoped lang="sass">
.calendar-weekly
  &-day
    border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))
</style>
