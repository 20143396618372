<template>
    <div class="feedback-menu">
        <v-tooltip location="bottom"
                   :text="t('nptb_feedbackMenu_title')">
            <template #activator="{ props }">
                <v-btn flat
                       icon
                       id="feedback-menu-activator"
                       v-bind="props">
                <v-icon>{{ icons.feedback }}</v-icon>
            </v-btn>
            </template>
        </v-tooltip>

        <v-menu activator="#feedback-menu-activator"
                width="300px">
            <v-list :density="props.density"
                    lines="two">
                <v-list-item :href="props.feedbackUrl"
                             target="_blank">
                    <template #title>{{ t(`nptb_feedbackMenu_feedback_label`) }}</template>
                    <template #subtitle>{{ t(`nptb_feedbackMenu_feedback_sublabel`) }}</template>
                    <template #append>
                        <v-icon>{{ icons.openInNew }}</v-icon>
                    </template>
                </v-list-item>
                <v-list-item :href="props.bugUrl"
                             target="_blank">
                    <template #title>{{ t(`nptb_feedbackMenu_bug_label`) }}</template>
                    <template #subtitle>{{ t(`nptb_feedbackMenu_bug_sublabel`) }}</template>
                    <template #append>
                        <v-icon>{{ icons.openInNew }}</v-icon>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiCommentEditOutline, mdiOpenInNew } from '@mdi/js'

const { t } = useI18n()

const props = defineProps({
    density: {
        type: String,
        default: 'default',
        validator: value => ['default', 'compact'].includes(value)
    },
    feedbackUrl: {
        type: String,
        default: process.env.VUE_APP_FEEDBACK_URL
    },
    bugUrl: {
        type: String,
        default: process.env.VUE_APP_BUG_URL
    }
})

const icons = ref({
    feedback: mdiCommentEditOutline,
    openInNew: mdiOpenInNew
})
</script>
