import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getAnalytics } from 'firebase/analytics'

const configuration = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

let appInstance
let firestoreInstance

export function initializeFirebase () {
  if (appInstance !== undefined) {
    return appInstance
  }

  appInstance = initializeApp(configuration)

  initializeAppCheck(
    appInstance,
    {
      provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_FIREBASE_APP_CHECK),
      isTokenAutoRefreshEnabled: true
    }) // https://firebase.google.com/docs/app-check

  firestoreInstance = getFirestore(appInstance) // https://firebase.google.com/docs/firestore
  enableIndexedDbPersistence(firestoreInstance)

  getPerformance(appInstance) // https://firebase.google.com/docs/perf-mon
  getAnalytics(appInstance) // https://firebase.google.com/docs/analytics

  return appInstance
}