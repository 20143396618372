<template>
    <div class="user-preference-menu">
        <v-tooltip location="bottom"
                   :text="t('nptb_userPreferenceMenu_title')">
            <template #activator="{ props }">
                <v-btn :disabled="props.isDisabled"
                       flat
                       icon
                       id="user-preference-menu-activator"
                       v-bind="props">
                <v-icon>{{ icons.preference }}</v-icon>
            </v-btn>
            </template>
        </v-tooltip>

        <v-menu activator="#user-preference-menu-activator"
                :close-on-content-click="false"
                width="300px">
            <v-list :density="density">
                <v-list-subheader>{{ t('nptb_userPreferenceMenu_density_label') }}</v-list-subheader>
                <v-list-item :disabled="props.density === density"
                             :key="`density_${density}`"
                             @click="emitDensity(density)"
                             v-for="density in props.availableDensities">
                    <template #title>{{ t(`nptb_userPreferenceMenu_density_${density}_label`) }}</template>
                    <template #append>
                        <v-icon v-if="props.density === density">{{ icons.checked }}</v-icon>
                    </template>
                </v-list-item>
                <v-divider />
                <v-list-subheader>{{ t('nptb_userPreferenceMenu_theme_label') }}</v-list-subheader>
                <v-list-item :disabled="props.theme === theme"
                             :key="`theme_${theme}`"
                             @click="emitTheme(theme)"
                             v-for="theme in props.availableThemes">
                    <template #title>{{ t(`nptb_userPreferenceMenu_theme_${theme}_label`) }}</template>
                    <template #append>
                        <v-icon v-if="props.theme === theme">{{ icons.checked }}</v-icon>
                    </template>
                </v-list-item>
                <v-divider />
                <v-list-subheader>{{ t('nptb_userPreferenceMenu_lang_label') }}</v-list-subheader>
                <v-list-item :disabled="props.lang === lang"
                             :key="`lang_${lang}`"
                             @click="emitLang(lang)"
                             v-for="lang in props.availableLangs">
                    <template #title>{{ t(`nptb_userPreferenceMenu_lang_${lang}_label`) }}</template>
                    <template #append>
                        <v-icon v-if="props.lang === lang">{{ icons.checked }}</v-icon>
                    </template>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiCogOutline, mdiRadioboxMarked } from '@mdi/js'

const { t } = useI18n()

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false
    },
    availableDensities: {
        type: Array,
        default: () => (['default', 'compact'])
    },
    availableThemes: {
        type: Array,
        default: () => (['default', 'light', 'dark'])
    },
    availableLangs: {
        type: Array,
        default: () => (['en', 'fr'])
    },
    density: {
        type: String,
        default: 'default',
        validator: (value) => ['default', 'compact'].includes(value)
    },
    theme: {
        type: String,
        default: 'default',
        validator: (value) => ['default', 'light', 'dark'].includes(value)
    },
    lang: {
        type: String,
        default: 'en',
        validator: (value) => ['en', 'fr'].includes(value)
    }
})

const emits = defineEmits(['update:density', 'update:theme', 'update:lang'])

const icons = ref({
    preference: mdiCogOutline,
    checked: mdiRadioboxMarked
})

function emitDensity (density) {
    emits('update:density', density)
}

function emitTheme (theme) {
    emits('update:theme', theme)
}

function emitLang (lang) {
    emits('update:lang', lang)
}
</script>
