/**
 * @file Service to handle **Application**.
 */
import { getAnalytics, logEvent } from 'firebase/analytics'

/**
 * Store of the app.
 */
export const store = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    density: undefined,
    theme: undefined,
    lang: navigator.language.split('-')[0]
  }),
  mutations: {
    load (state) {
      state.isLoading = true
    },
    loaded (state) {
      state.isLoading = false
    },
    setDensity (state, density) {
      state.density = density
    },
    setTheme (state, theme) {
      state.theme = theme
    },
    setLang (state, lang) {
      state.lang = lang || navigator.language.split('-')[0]
    }
  },
  actions: {
    load ({ commit }) {
      commit('load')
    },
    loaded ({ commit }) {
      commit('loaded')
    },
    setDensity({ commit }, density) {
      commit('setDensity', density)
    },
    setTheme({ commit }, theme) {
      commit('setTheme', theme)
    },
    setLang({ commit }, lang) {
      commit('setLang', lang)
    }
  }
}

/**
 * Log event {@link name} to Google Analytics.
 * @param {string} name 
 * @param {object} params
 * @returns {*}
 */
export function logApp (name, params) {
  return logEvent(getAnalytics(), name, params)
}
