<template>
    <v-menu class="user-profile-menu"
            width="300px">
        <template #activator="{ props }">
            <v-btn :disabled="props.isDisabled"
                   flat
                   icon
                   v-bind="props">
                <v-avatar color="primary">
                    <v-img :src="props.user.photoURL"
                           v-if="hasPicture" />
                    <span v-else>{{ pictureFallback }}</span>
                </v-avatar>
            </v-btn>
        </template>

        <v-list :density="props.density">
            <v-list-item lines="two">
                <template #prepend>
                    <v-avatar color="primary">
                        <v-img :src="props.user.photoURL"
                               v-if="hasPicture" />
                        <span v-else>{{ pictureFallback }}</span>
                    </v-avatar>
                </template>
                <template #title>{{ t('nptb_userProfileMenu_displayName', { value: props.user?.displayName }) }}</template>
                <template #subtitle>{{ props.user?.ldap }} - {{ props.user.buCode?.toUpperCase() }}</template>
            </v-list-item>

            <v-divider v-if="bus.length > 0"/>
            
            <v-list-item :key="`nptb-bu-selector-item-${idx}`"
                            v-for="(bu, idx) in bus"
                            @click.stop="changeBuId(bu.id)">
                {{ bu.name }}
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { updateUserProfile, findUserProfile } from '../services/repositories/userProfileRepository'

const { t } = useI18n()

const { dispatch } = useStore()

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false
    },
    density: {
        type: String,
        default: 'default',
        validator: value => ['default', 'compact'].includes(value)
    },
    user: {
        type: Object
    }
})

const hasPicture = computed(() => props.user?.photoURL)
const pictureFallback = computed(() => props.user ? props.user.displayName.charAt(0).toUpperCase() : '...')
const bus = computed(() => {
    if (props.user.buCode !== 'GO-LM') {
        return []
    }
    return [
        {
            id: 10,
            name : 'Bricocenter Italia'
        },
        {
            id: 7,
            name: 'Leroy Merlin Brasil'
        },
        {
            id: 2,
            name: 'Leroy Merlin España'
        },
        {
            id: 1,
            name: 'Leroy Merlin France'
        },
        {
            id: 5,
            name: 'Leroy Merlin Italia'
        },
        {
            id: 3,
            name: 'Leroy Merlin Portugal'
        }
    ]
})

async function changeBuId(bu) {
    await updateUserProfile({ selectedBuId: bu })
    let userProfile = await findUserProfile()
    dispatch('user/setProfile', userProfile)
}
</script>
