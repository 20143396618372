<template>
  <div class="calendar-monthly"
       :class="`calendar-monthly-cols-${numberOfDaysInWeek} calendar-monthly-lines-${numberOfLines}`">
    <v-card class="calendar-monthly-day"
            :color="day.isActualMonth ? '' : 'grey-lighten-1'"
            flat
            height="100%"
            :key="`day_${index}`"
            :rounded="0"
            v-on="day.isDisabled ? {} : { click: () => emitNewEvent(day.value) }"
            v-for="(day, index) in days">
      <v-list-item>
        <template #prepend>
          <v-avatar :color="day.isToday ? 'primary' : undefined"
                    size="x-small">
            {{ d(day.value, 'onlyDay') }}
          </v-avatar>
        </template>
        <template #title
                  v-if="index < numberOfDaysInWeek">
            {{ d(day.value, 'onlyWeekDay') }}
        </template>
        <template #append
                  v-if="index % numberOfDaysInWeek === 0">
          <v-chip size="x-small">{{ t('nptb_calendarMonthly_weekNumber', { value: day.weekNumber }) }}</v-chip>
        </template>
      </v-list-item>

      <v-card-text class="pt-0 pb-0">
        <v-chip class="calendar-monthly-day-event mb-1"
                :color="colors[event.status]"
                density="compact"
                :key="`calendar-monthly-day-event_${index}`"
                label
                @click.stop="displayFullDetailsOfDay(day)"
                v-for="(event, index) in day.events.slice(0, props.maxEvents)">
          <v-icon class="mr-1" size="small">{{ icons[event.status] }}</v-icon>
          <span class="text-truncate">{{ event.name }}</span>
        </v-chip>

        <v-chip class="calendar-monthly-day-event"
                density="compact"
                label
                variant="plain"
                @click.stop="displayFullDetailsOfDay(day)"
                v-if="day.events.length > props.maxEvents">
          +{{ day.events.length - props.maxEvents }}
        </v-chip>
      </v-card-text>
    </v-card>

    <v-dialog max-width="800px"
              v-model="isDialogOpened">
        <NptbCalendarDaily :modelValue="selectedDay"
                           @delete:event="emitDelete"
                           @click:new:event="emitNewEvent(selectedDay.value)" />
    </v-dialog>
    
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { DateTime, Interval } from 'luxon'
import { mdiProgressClock, mdiProgressHelper, mdiCheckCircleOutline } from '@mdi/js'

import NptbCalendarDaily from './NptbCalendarDaily'

const props = defineProps({
  withoutWeekends: {
    type: Boolean,
    default: false
  },
  date: {
    type: Date,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => ([])
  },
  minDate: {
    type: Date
  },
  maxDate: {
    type: Date
  },
  maxEvents: {
    type: Number,
    default: 2
  }
})

const emits = defineEmits(['update:rangeDates', 'delete:event', 'click:new:event'])

const { d, t } = useI18n()

const DEFAULT_DAY_FORMAT = 'yyyy-LL-dd'

const icons = ref({
  TODO: mdiProgressClock,
  DOING: mdiProgressHelper,
  DONE: mdiCheckCircleOutline
})

const colors = ref({
  TODO: 'grey',
  DOING: 'primary',
  DONE: 'success'
})

const startDate = computed(() => DateTime.fromJSDate(props.date).startOf('month').startOf('week'))
const endDate = computed(() => DateTime.fromJSDate(props.date).endOf('month').endOf('week'))

const numberOfDaysInWeek = computed(() => props.withoutWeekends ? 5 : 7)

const isDialogOpened = ref(false)

const selectedDay = ref(undefined)

const days = computed(() => Interval.fromDateTimes(startDate.value, endDate.value)
                                    .splitBy({ day: 1 })
                                    .map(dt => {
                                      const isActualMonth = DateTime.fromJSDate(props.date).month === dt.start.month
                                      const isToday = DateTime.local().hasSame(dt.start, 'day') 
                                                      && DateTime.local().hasSame(dt.start, 'month')
                                                      && DateTime.local().hasSame(dt.start, 'year')
                                      const value = dt.start.toJSDate()
                                      const weekNumber = dt.start.weekNumber
                                      const events = props.modelValue.filter(event => {
                                        const eventDateTime = DateTime.fromFormat(event.startDate.value, DEFAULT_DAY_FORMAT)
                                        return dt.start.hasSame(eventDateTime, 'day')
                                            && dt.start.hasSame(eventDateTime, 'month')
                                            && dt.start.hasSame(eventDateTime, 'year')
                                      })

                                      let isDisabled = false

                                      if (props.minDate) {
                                        isDisabled = dt.start < DateTime.fromJSDate(props.minDate)
                                      }

                                      if (!isDisabled && props.maxDate) {
                                        isDisabled = dt.start > DateTime.fromJSDate(props.maxDate)
                                      }

                                      return {
                                        value,
                                        weekNumber,
                                        isActualMonth,
                                        isToday,
                                        isDisabled,
                                        events
                                      }
                                    }))



const numberOfLines = computed(() => days.value.length / numberOfDaysInWeek.value)

function emitNewEvent (day) {
  emits('click:new:event', day)
}

function emitDelete (id) {
  isDialogOpened.value = false
  emits('delete:event', id)
}

function displayFullDetailsOfDay(day) {
  isDialogOpened.value = true
  selectedDay.value = day
}

watch(
  [ startDate, endDate ],
  (rangeDates) => {
    if (rangeDates[0] && rangeDates[1]) {
      emits('update:rangeDates', rangeDates.map(rd => rd.toJSDate()))
    }
  })

onMounted(() => {
  emits('update:rangeDates', [startDate.value, endDate.value].map(rd => rd.toJSDate()))
})
</script>

<style scoped lang="sass">
.calendar-monthly
  display: grid
  height: 100%

  &-day
    border: none
    border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))

    &-event
      width: 100%

      &-more
        right: 10px
        bottom: 10px

  &-day + &-day
    border-left: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))

  &-cols
    &-7
      grid-template-columns: repeat(7, 1fr)

  &-lines
    &-4
      grid-template-rows: repeat(4, 1fr)

    &-5
      grid-template-rows: repeat(5, 1fr)

    &-6
      grid-template-rows: repeat(6, 1fr)
</style>
