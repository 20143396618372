import { collection, doc, getDoc, updateDoc, serverTimestamp, getFirestore } from 'firebase/firestore'

import { isUserAuthenticated, getAuthenticatedUser } from '@/features/authentication/services/authenticationService'

/**
 * Find preference for the authenticated user.
 * @return {Promise<*>}
 */
export async function findUserPreference () {
  const isAuthenticatedUser = await isUserAuthenticated()

  if (!isAuthenticatedUser) {
    return undefined
  }

  const id = getAuthenticatedUser().uid
  const usersPreferenceCollection = collection(getFirestore(), 'users_preference')
  const documentSnapshot = await getDoc(doc(usersPreferenceCollection, id))

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  return undefined
}

/**
 * Update {@link userPreference} for the authenticated user.
 * @param {object} userPreference
 * @param {string="default"} userPreference.density Can be "default", "compact"
 * @param {string="default"} userPreference.theme Can be "default", "light", "dark"
 * @return {Promise<*>}
 */
export async function updateUserPreference (userPreference = { density: 'default', theme: 'default' }) {
  const id = getAuthenticatedUser().uid
  const usersPreferenceCollection = collection(getFirestore(), 'users_preference')
  const documentReference = doc(usersPreferenceCollection, id)

  return updateDoc(
    documentReference,
    {
      lastModifiedAt: serverTimestamp(),
      ...userPreference
    })
}
