import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * Create a new {@link campaign}
 * @param {object} campaign
 * @param {string} campaign.name
 * @param {date} startDate
 * @param {number} targetsSize
 * @param {array<string>} targetsIncluded
 * @param {array<string>} targetsExcluded
 * @returns {Promise}
 */
export function insertCampaign(campaign) {

    return httpsCallable(
        getFunctions(getApp(), 'europe-west1'), 
        'nptb_insertCampaign')(campaign)
    .then(response => response.data)

}

/**
 * Delete a campaign by its {@link id}
 * @param {string} id
 * @returns {Promise}
 */
export function deleteCampaignById(id) {

    return httpsCallable(
        getFunctions(getApp(), 'europe-west1'), 
        'nptb_deleteCampaign')({ id })
    .then(response => response.data)

}

/**
 * Get all campaigns
 * @param {date} startDate
 * @param {date} endDate
 * @returns {Promise}
 */
export function getCampaigns(startDate, endDate) {

    return httpsCallable(
        getFunctions(getApp(), 'europe-west1'),
        'nptb_getCampaigns')({ startDate, endDate })
    .then(response => response.data)
    
}

/**
 * Get the date of the next campaign with 'TODO' status
 * @returns {Promise}
 */
export function getNextCampaignDate() {
    return httpsCallable(
        getFunctions(getApp(), 'europe-west1'),
        'nptb_getNextCampaignDate')().then(response => response.data)
}
