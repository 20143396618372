import { createRouter, createWebHistory } from 'vue-router'

import i18n from './registerI18n'

import authenticationRoutes from './features/authentication/authenticationRoutes'
import campaignRoutes from './features/campaign/campaignRoutes'

import { isUserAuthenticated, logIn } from './features/authentication/services/authenticationService'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Default',
      redirect: { name: 'CampaignDefault' }
    },
    ...authenticationRoutes,
    ...campaignRoutes
  ]
})

router.beforeEach(async (to, _, next) => {
  if (to.meta.hasToBeAuthenticated 
      && !(await isUserAuthenticated())) {
    logIn()
    next(false)
  }

  const applicationTitle = i18n.global.t('nptb_application_title')
  const applicationCompany = i18n.global.t('nptb_application_company')
  const viewTitle = i18n.global.t(to.meta?.title)
  document.title = `${viewTitle} - ${applicationTitle} - ${applicationCompany}`
  next()
})

export default router
