import CampaignLayout from './views/CampaignLayout'
import CampaignCalendar from './views/CampaignCalendar'
import CampaignNew from './views/CampaignNew'

export default [
    {
      path: '/campaigns',
      component: CampaignLayout,
      children: [
        {
          path: '',
          name: 'CampaignDefault',
          redirect: { name: 'CampaignCalendar' }
        },
        {
          path: 'calendar',
          name: 'CampaignCalendar',
          meta: {
            title: 'nptb_campaignCalendar_title',
            hasHeader: true,
            hasNavigation: true,
            hasToBeAuthenticated: true
          },
          component: CampaignCalendar
        },
        {
          path: 'new',
          name: 'CampaignNew',
          meta: {
            title: 'nptb_campaignNew_title',
            hasHeader: true,
            hasNavigation: true,
            hasToBeAuthenticated: true
          },
          component: CampaignNew
        }
      ]
    }
  ]