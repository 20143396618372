<template>
    <v-form class="campaign-new-form"
            id="campaign-new-form"
            lazy-validation
            ref="form"
            @submit.prevent="emitSubmit">
        
        <v-row>
            <v-col cols="12"
                   md="8">
                <v-text-field autocomplete="on"
                              autofocus
                              :density="density"
                              :disabled="props.isDisabled"
                              :label="t('nptb_campaignNew_name_label')"
                              v-model.trim="campaign.name"
                              required
                              :rules="nameRules"
                              variant="outlined" />
            </v-col>
            <v-col cols="12"
                   md="4">
                <v-text-field autocomplete="off"
                              :density="density"
                              :disabled="props.isDisabled"
                              :label="t('nptb_campaignNew_startDate_label')"
                              :min="minStartDateFormatted"
                              :max="maxStartDateFormatted"
                              v-model="campaign.startDate"
                              required
                              :rules="startDateRules"
                              type="date"
                              variant="outlined" />
            </v-col>
        </v-row>

         <v-row>
            <v-col cols="12">
                <v-text-field autocomplete="off"
                              :density="density"
                              :disabled="props.isDisabled"
                              :hint="t('nptb_campaignNew_targetsSize_hint')"
                              :label="t('nptb_campaignNew_targetsSize_label')"
                              min="1"
                              v-model.number="campaign.targetsSize"
                              persistentHint
                              required
                              :rules="targetsSizeRules"
                              type="number"
                              variant="outlined" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <section>
                    <h6 class="text-subtitle-2 mb-4">{{ t('nptb_campaignNew_targetsIncluded_title') }}</h6>
                    <NomenclatureSelect :buId="userProfile?.selectedBuId"
                                        :isDisabled="props.isDisabled"
                                        isRequired
                                        :rules="targetsIncludedRules"
                                        v-model="campaign.targetsIncluded" />
                </section>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-row v-if="campaign.targetsIncluded.length > 0">
                <v-col cols="12">
                    <section>
                        <h6 class="text-subtitle-2 mb-4">{{ t('nptb_campaignNew_targetsExcluded_title') }}</h6>
                        <NomenclatureSelect :buId="userProfile?.selectedBuId"
                                            :isDisabled="props.isDisabled"
                                            :rules="targetsExcludedRules"
                                            v-model="campaign.targetsExcluded" />
                    </section>
                </v-col>
            </v-row>
        </v-expand-transition>

        <v-row class="mb-2">
            <v-col>
                <v-btn color="primary" 
                       :density="density"
                       :disabled="props.isDisabled"
                       type="submit"
                       variant="flat">
                    {{ t('nptb_campaignNew_create') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'

import NomenclatureSelect from '@/features/nomenclature/components/NomenclatureSelect'

const route = useRoute()

const store = useStore()

const { t } = useI18n()

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['submit'])

const DEFAULT_DAY_FORMAT = 'yyyy-LL-dd'

const density = computed(() => store.state.app.density)

const userProfile = computed(() => store.state.user.profile)

const minStartDate = ref(DateTime.local().plus({ day: 5 }))
const minStartDateFormatted = computed(() => minStartDate.value.toFormat(DEFAULT_DAY_FORMAT))

const maxStartDate = ref(DateTime.local().plus({ year: 1 }))
const maxStartDateFormatted = computed(() => maxStartDate.value.toFormat(DEFAULT_DAY_FORMAT))

const startDateFormatted = computed(() => 
    route.query?.date && DateTime.fromFormat(route.query.date, DEFAULT_DAY_FORMAT) > minStartDate.value && DateTime.fromFormat(route.query.date, DEFAULT_DAY_FORMAT) < maxStartDate.value 
    ? route.query.date 
    : undefined
)

const campaign = ref({
    name: undefined,
    startDate: startDateFormatted.value,
    targetsSize: 60000,
    targetsIncluded: [],
    targetsExcluded: []
})

const form = ref(null)

const nameRules = ref([
    v => !!v || t('nptb_campaignNew_name_error_required')
])

const startDateRules = ref([
    v => !!v || t('nptb_campaignNew_startDate_error_required'),
    v => DateTime.fromFormat(v, DEFAULT_DAY_FORMAT) > minStartDate.value || t('nptb_campaignNew_startDate_error_invalid'),
    v => DateTime.fromFormat(v, DEFAULT_DAY_FORMAT) < maxStartDate.value || t('nptb_campaignNew_startDate_error_invalid'),
])

const targetsSizeRules = ref([
    v => !!v || t('nptb_campaignNew_targetsSize_error_required'),
    v => !Number.isNaN(v) && v > 0 || t('nptb_campaignNew_targetsSize_error_invalid'),
])

const targetsIncludedRules = ref([
    v => v.length > 0 || t('nptb_campaignNew_targetsIncluded_error_required'),
    v => v.length <= 10 || t('nptb_campaignNew_targetsIncluded_error_invalid'),
])

const targetsExcludedRules = ref([
    v => v.length <= 10 || t('nptb_campaignNew_targetsExcluded_error_invalid'),
])

async function emitSubmit() {
    const { valid } = await form.value.validate()

    if (valid) {
        emits(
            'submit', 
            {
                name: campaign.value.name.charAt(0).toUpperCase() + campaign.value.name.substr(1),
                startDate: campaign.value.startDate,
                targetsSize: Number(campaign.value.targetsSize),
                targetsIncluded: campaign.value.targetsIncluded,
                targetsExcluded: campaign.value.targetsExcluded
            }
        )
    }
}
</script>