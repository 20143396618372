<template>
    <v-container class="campaign-new"
                 fluid
                 id="campaign-new">
        <v-row>
            <v-col cols="12"
                   md="6"
                   offset-md="3">
                <NptbTitle class="mt-5 mb-5"
                           :backTo="backTo">
                    {{ t('nptb_campaignNew_title') }}
                </NptbTitle>
                
                <CampaignNewForm :isDisabled="isCreatingNewCampaign"
                                 @submit="insertNewCampaign" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { reportError } from '@/registerErrorReporting'

import NptbTitle from '@/components/NptbTitle'

import CampaignNewForm from '../components/CampaignNewForm'

import { insertCampaign } from '../services/repositories/campaignRepository'

const route = useRoute()
const router = useRouter()

const { t } = useI18n()

const { dispatch } = useStore()

const backTo = computed(() => 
    route.query?.from 
    ? decodeURIComponent(route.query.from) 
    : { name: 'CampaignDefault' }
)

const isCreatingNewCampaign = ref(false)
const isFailedToCreateNewCampaign = ref(false)


async function insertNewCampaign (campaign) {
    dispatch('app/load')
    
    isCreatingNewCampaign.value = true
    isFailedToCreateNewCampaign.value = false

    try {
        await insertCampaign(campaign)
        router.push(backTo.value)
    } catch (error) {
        isFailedToCreateNewCampaign.value = true
        reportError(new Error(`createNewCampaign-0001 : failed to insert a new campaign due to : "${error.message}".`))
    } finally {
        dispatch('app/loaded')
        isCreatingNewCampaign.value = false
    }
}
</script>