<template>
    <v-autocomplete class="nomenclature-select"
                    clearable
                    :density="props.density"
                    :disabled="isDisabled"
                    :filter-keys="['title', 'value']"
                    :items="nomenclatures"
                    item-title="label"
                    item-value="id"
                    :placeholder="t('nptb_nomenclature_select_placeholder', props.modelValue.length)"
                    :loading="isLoading"
                    :menu-props="{ maxHeight: '400px' }"
                    :modelValue="props.modelValue"
                    multiple
                    open-on-clear
                    :required="props.isRequired"
                    :rules="props.rules"
                    variant="outlined"
                    @click:clear="emitModelValue([])">
        <template #chip="{ item }">
            <v-chip class="mr-1">
                <strong class="text-primary mr-1">{{ item.value }}</strong>
                <span>{{ item.title }}</span>
            </v-chip>
        </template>

        <template #item="activator">
            <v-list-item :class="{ 'ml-8': activator.item.raw.typeId, 'ml-4': activator.item.raw.subDepartmentId}"
                         :disabled="isLoading"
                         @click="getNomenclatures(activator.item.raw.departmentId, activator.item.raw.subDepartmentId)">
                <template #prepend>
                    <v-list-item-action start>
                        <v-checkbox-btn multiple
                                        :modelValue="props.modelValue"
                                        :value="activator.props.value"
                                        @update:modelValue="emitModelValue" />
                    </v-list-item-action>
                </template>
                <template #title>
                    <strong class="text-primary mr-1">{{ activator.item.value }}</strong>
                    <span class="text-truncate">{{ activator.item.title }}</span>
                </template>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script setup>
import { onMounted, defineProps, ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { 
    findNomenclatureDepartments,
    findNomenclatureSubDepartments,
    findNomenclatureTypes
} from '../services/repositories/nomenclaturesRepository'

const { t } = useI18n()

const props = defineProps({
    buId: {
        type: Number
    },
    density: {
        type: String,
        default: 'default',
        validator: value => ['default', 'compact'].includes(value)
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
    isRequired: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: Array,
        default: () => ([])
    },
    rules: {
        type: Array,
        default: () => ([])
    }
})

const emits = defineEmits(['update:modelValue'])

const isLoading = ref(true)
const isDisabled = computed(() => props.isDisabled || nomenclatures.value.length === 0)

const nomenclatures = ref([])


async function getNomenclatures(departmentId, subDepartmentId) {
    if (subDepartmentId) {
        if (nomenclatures.value.find(nomenclature => nomenclature.id.startsWith(`${departmentId}_${subDepartmentId}_`))) {
            return
        }
    } else if (departmentId) {
        if (nomenclatures.value.find(nomenclature => nomenclature.id.startsWith(`${departmentId}_`))) {
            return
        }
    }

    isLoading.value = true

    try {
        if (subDepartmentId) {
            const idx = nomenclatures.value.findIndex(nomenclature => nomenclature.subDepartmentId === subDepartmentId)
            const nomenclaturesTypes = await findNomenclatureTypes(props.buId, departmentId, subDepartmentId)
            nomenclatures.value.splice(idx+1, 0, ...nomenclaturesTypes)
        } else if (departmentId) {
            const idx = nomenclatures.value.findIndex(nomenclature => nomenclature.departmentId === departmentId)
            const nomenclaturesSubDepartments = await findNomenclatureSubDepartments(props.buId, departmentId)
            nomenclatures.value.splice(idx+1, 0, ...nomenclaturesSubDepartments)
        } else {
            nomenclatures.value = await findNomenclatureDepartments(props.buId)
        }
    } finally {
        isLoading.value = false
    }
}

function emitModelValue (modelValue) {
    emits('update:modelValue', modelValue)
}

onMounted( () => {
    if (props.buId) {
        getNomenclatures()
    }
})

watch(() => props.buId, () => {
    getNomenclatures()
})
</script>