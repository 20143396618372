<template>
    <v-card class="calendar-daily mb-4"
            variant="outlined"
            v-on="props.modelValue.isDisabled || props.modelValue.events.length > 0 ? {} : { click: emitNewEvent }">
        <v-list class="pa-0"
                lines="two">
          <v-list-item>
            <template #prepend>
              <v-avatar :color="props.modelValue.isToday ? 'primary' : undefined">
                {{ d(props.modelValue.value, 'onlyDay') }}
              </v-avatar>
            </template>
            <template #title>
              <span class="text-capitalize">{{ d(props.modelValue.value, 'onlyWeekDayLong') }}</span>
            </template>
            <template #subtitle>{{ t('nptb_calendarDaily_campaigns', props.modelValue.events.length, { count: props.modelValue.events.length }) }}</template>
          </v-list-item>
          <v-divider v-if="props.modelValue.events.length > 0"/>
        </v-list>

        <v-btn class="calendar-daily-new"
               color="primary"
               icon
               size="small"
               @click="emitNewEvent"
               v-if="!props.modelValue.isDisabled && props.modelValue.events.length > 0">
          <v-icon>{{ icons.new }}</v-icon>
        </v-btn>

        <div style="max-height:500px;overflow:auto" v-if="props.modelValue.events.length > 0">
          <v-list lines="three"
                  v-if="props.modelValue.events.length > 0">
            <v-list-item class="v-list-no-limit"
                         :color="colors[event.status]"
                         :key="`calendar-daily-event-${index}`"
                         v-for="(event, index) in props.modelValue.events">
              <template #prepend>
                <v-tooltip location="bottom"
                          :text="t(`nptb_calendarDaily_${event.status.toLowerCase()}`)">
                  <template v-slot:activator="{ props }">
                    <v-avatar v-bind="props">
                      <v-icon :color="colors[event.status]">{{ icons[event.status] }}</v-icon>
                    </v-avatar>
                  </template>
                </v-tooltip>
              </template>

              <template #title>
                <span :class="`text-${colors[event.status]}`">
                  <strong>{{ event.id }}</strong>
                  {{ event.name }}
                </span>
              </template>

              <template #subtitle>
                <p>{{ t('nptb_calendarDaily_quantity', { value: n(event.targetsSize) }) }}</p>
                <p>
                  {{ t('nptb_calendarDaily_offers', event.targetsIncluded.length, {count: event.targetsIncluded.length}) }}
                  <span class="text-success">{{ event.targetsIncluded.map(target => `${target.id} ${target.label}`).join(', ') }}</span>
                </p>
                <p>
                  {{ t('nptb_calendarDaily_filters', event.targetsExcluded.length, {count: event.targetsExcluded.length}) }}
                  <span class="text-warning">{{ event.targetsExcluded.map(target => `${target.id} ${target.label}`).join(', ') }}</span>
                </p>
              </template>

              <template #append
                        v-if="event.status === 'TODO'">
                <v-list-item-action>
                  <v-slide-x-reverse-transition>
                    <v-btn icon
                            flat
                            @click.stop="cancelDeletion(event.id)"
                            v-if="isConfirmingBeforeDeletion[event.id]">
                      <v-icon>{{ icons.cancelDeletion }}</v-icon>
                    </v-btn>
                  </v-slide-x-reverse-transition>
                  <v-btn :color="isConfirmingBeforeDeletion[event.id] ? 'warning' : ''"
                        flat
                        icon
                        v-on.stop="isConfirmingBeforeDeletion[event.id] ? { click : () => emitDelete(event.id) } : { click : () => confirmBeforeDeletion(event.id) }">
                    <v-icon>{{ icons.delete }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list>
        </div>
    </v-card>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiCancel, mdiCheck, mdiCheckCircleOutline, mdiPlus, mdiProgressClock, mdiProgressHelper, mdiTrashCanOutline } from '@mdi/js'

const { d, t, n } = useI18n()

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits(['delete:event', 'click:new:event'])

const icons = ref({
  TODO: mdiProgressClock,
  DOING: mdiProgressHelper,
  DONE: mdiCheckCircleOutline,
  new: mdiPlus,
  delete: mdiTrashCanOutline,
  cancelDeletion: mdiCancel,
  confirmDeletion: mdiCheck,
})

const colors = ref({
  TODO: 'grey',
  DOING: 'primary',
  DONE: 'success'
})

const isConfirmingBeforeDeletion = ref({})


function confirmBeforeDeletion (id) {
  isConfirmingBeforeDeletion.value[id] = true

  window.setTimeout(() => cancelDeletion(id), 3000)
}

function cancelDeletion (id) {
  isConfirmingBeforeDeletion.value[id] = false
}

function emitDelete (id) {
  emits('delete:event', id)
}

function emitNewEvent () {
  emits('click:new:event')
}
</script>

<style scoped lang="sass">
.calendar-daily
  position: relative

  &-new
    position: absolute
    right: 20px
    top: 45px
    z-index: 999
</style>