import { createStore } from 'vuex'

import { store as appStore } from '@/services/appService'

import { store as userStore } from '@/features/user/services/userService'

export default createStore({
  modules: {
    app: appStore,
    user: userStore
  }
})
