import { getFirestore, collection, where, orderBy, query, onSnapshot } from 'firebase/firestore'

/**
 * Find alerts by {@link buId}.
 * @param {number} buId e.g. 1
 * @return {Promise<Array>}
 */
export async function findAllAlertsAsync (buId, callbackFn) {
  const whereClauses = [
    where('buId', '==', Number(buId)),
  ]

  const orderByClauses = [
    orderBy('createdAt', 'desc')
  ]
  
  return onSnapshot(
    query(
      collection(getFirestore(), 'alerts'),
      ...whereClauses,
      ...orderByClauses),
    querySnapshot => {
      const alerts = []
      
      querySnapshot.docs.forEach(
        documentSnapshot => alerts.push({ id: documentSnapshot.id, ...documentSnapshot.data() })
      )

      callbackFn(alerts)
    })
}