export default {
  "nptb_application_shortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPTB"])},
  "nptb_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Product To Buy"])},
  "nptb_application_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "nptb_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeo"])},
  "nptb_application_noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
  "nptb_application_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
  "nptb_application_menu_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "nptb_application_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "nptb_form_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "nptb_campaign_status_todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
  "nptb_campaign_status_doing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doing"])},
  "nptb_campaign_status_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "nptb_authenticationCallback_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
  "nptb_authenticationCallback_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "nptb_authenticationCallback_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to authenticate you."])},
  "nptb_authenticationCallback_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_application_retry", undefined, _type)])},
  "nptb_authenticationLogOut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "nptb_authenticationLogOut_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "nptb_authenticationLogOut_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to log out you."])},
  "nptb_authenticationLogOut_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_application_retry", undefined, _type)])},
  "nptb_userPreferenceMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My preferences"])},
  "nptb_userPreferenceMenu_density_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display density"])},
  "nptb_userPreferenceMenu_density_default_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default"])},
  "nptb_userPreferenceMenu_density_compact_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact"])},
  "nptb_userPreferenceMenu_theme_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
  "nptb_userPreferenceMenu_theme_default_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default"])},
  "nptb_userPreferenceMenu_theme_light_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "nptb_userPreferenceMenu_theme_dark_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "nptb_userPreferenceMenu_lang_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "nptb_userPreferenceMenu_lang_fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "nptb_userPreferenceMenu_lang_en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "nptb_userProfileMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
  "nptb_userProfileMenu_displayName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("value"))])},
  "nptb_userProfileMenu_logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "nptb_alertMenu_alerts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No notification"]), _normalize([_interpolate(_named("count")), " notification"]), _normalize([_interpolate(_named("count")), " notifications"])])},
  "nptb_alertMenu_createdAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created at ", _interpolate(_named("date"))])},
  "nptb_feedbackMenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & feedback"])},
  "nptb_feedbackMenu_feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a feedback"])},
  "nptb_feedbackMenu_feedback_sublabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Tangram"])},
  "nptb_feedbackMenu_bug_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declare a bug"])},
  "nptb_feedbackMenu_bug_sublabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Service Now"])},
  "nptb_calendarMonthly_weekNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["W.", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
  "nptb_campaignCalendar_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new campaign"])},
  "nptb_campaignCalendar_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "nptb_campaignCalendar_temporality_select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_mode_month_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "nptb_campaignCalendar_mode_week_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "nptb_campaignCalendar_weekNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Week ", _interpolate(_named("value"))])},
  "nptb_campaignCalendar_week": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("startDate")), " to ", _interpolate(_named("endDate"))])},
  "nptb_campaignNew_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a campaign"])},
  "nptb_campaignNew_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "nptb_campaignNew_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "nptb_campaignNew_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "nptb_campaignNew_name_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_startDate_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "nptb_campaignNew_startDate_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_startDate_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid, must be a date at min. D+5 and max. Y+1"])},
  "nptb_campaignNew_targetsSize_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of customers to target"])},
  "nptb_campaignNew_targetsSize_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_targetsSize_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid, must be a number at min. 1"])},
  "nptb_campaignNew_targetsSize_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluded customers who purchased on the latest 10 weeks"])},
  "nptb_campaignNew_targetsIncluded_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
  "nptb_campaignNew_targetsIncluded_error_required": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_form_required", undefined, _type)])},
  "nptb_campaignNew_targetsIncluded_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid, must be less or equal to 10 items"])},
  "nptb_campaignNew_targetsExcluded_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "nptb_campaignNew_targetsExcluded_error_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid, must be less or equal to 10 items"])},
  "nptb_nomenclature_select_placeholder": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Search in the nomenclature"]), _normalize(["Continue ?"]), _normalize(["Continue ?"])])},
  "nptb_calendarDaily_quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quantity : ", _interpolate(_named("value"))])},
  "nptb_calendarDaily_offers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " offer :"]), _normalize([_interpolate(_named("count")), " offers :"])])},
  "nptb_calendarDaily_filters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Without filter"]), _normalize([_interpolate(_named("count")), " filter :"]), _normalize([_interpolate(_named("count")), " filters :"])])},
  "nptb_calendarDaily_campaigns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No planned campaign"]), _normalize([_interpolate(_named("count")), " planned campaign"]), _normalize([_interpolate(_named("count")), " planned campaigns"])])},
  "nptb_calendarDaily_todo": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_todo", undefined, _type)])},
  "nptb_campaignDaily_doing": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_doing", undefined, _type)])},
  "nptb_campaignDaily_done": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_done", undefined, _type)])},
  "nptb_campaignCalendar_legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
  "nptb_campaignCalendar_todo": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_todo", undefined, _type)])},
  "nptb_campaignCalendar_doing": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_doing", undefined, _type)])},
  "nptb_campaignCalendar_done": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("nptb_campaign_status_done", undefined, _type)])},
  "nptb_campaignCalendar_nextCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next campaigns"])},
  "nptb_campaignCalendar_noNextCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have campaign created for this week"])}
}