import StackdriverErrorReporter from 'stackdriver-errors-js'

let errorHandler

export async function initializeErrorReporting () {
  if (errorHandler !== undefined) {
    return errorHandler
  }

  let service
  let version
  let tangram

  try {
    const productInfo = require('../public/product-info.json')
    service = productInfo.name
    version = productInfo.version
    tangram = productInfo.tangram_id
  } catch (error) {
    const productInfo = require('../../../product-info.json')
    service = productInfo.name
    version = productInfo.version
    tangram = productInfo.tangram_id
  }
  finally {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: process.env.VUE_APP_ERROR_REPORTING_API_KEY || '',
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      service,
      version,
      tangram
    })
  }
}

export function reportError (error) {
  if (errorHandler !== undefined) {
    errorHandler.report(error)
  } else {
    throw error
  }
}
