<template>
  <div class="campaign-layout"
       id="campaign-layout">
    <router-view />
  </div>
</template>

<style scoped lang="sass">
.campaign-layout
  height: 100%
</style>