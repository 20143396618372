import { collection, doc, getFirestore, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'

import { isUserAuthenticated, getAuthenticatedUser } from '@/features/authentication/services/authenticationService'

/**
 * Find profile for the authenticated user.
 * @return {Promise<*>}
 */
export async function findUserProfile () {
  const isAuthenticatedUser = await isUserAuthenticated()

  if (!isAuthenticatedUser) {
    return undefined
  }

  const id = getAuthenticatedUser().uid
  const usersProfileCollection = collection(getFirestore(), 'users_profile')
  const documentSnapshot = await getDoc(doc(usersProfileCollection, id))

  if (documentSnapshot.exists()) {
    return { id: documentSnapshot.id, ...documentSnapshot.data() }
  }

  return undefined
}

/**
 * Update {@link userProfile} for the authenticated user.
 * @param {object} userProfile
 * @return {Promise<*>}
 */
export async function updateUserProfile (userProfile) {
  const id = getAuthenticatedUser().uid
  const usersProfileCollection = collection(getFirestore(), 'users_profile')
  const documentReference = doc(usersProfileCollection, id)

  return updateDoc(
    documentReference,
    {
      lastModifiedAt: serverTimestamp(),
      ...userProfile
    })
}
