<template>
    <div class="alert-menu">
        <v-tooltip location="bottom"
                   :text="t('nptb_alertMenu_alerts', alerts.length, { count: alerts.length })">
            <template #activator="{ props }">
                <v-btn  :disabled="isDisabled"
                        flat
                        id="alert-menu-activator"
                        icon
                        v-bind="props">
                    <v-badge bordered
                             dot
                             :color="badgeColor"
                             :modelValue="hasAlerts">
                        <v-icon v-if="hasAlerts">{{ icons.alerts }}</v-icon>
                        <v-icon v-else>{{ icons.noAlerts }}</v-icon>
                    </v-badge>
                </v-btn>
            </template>
        </v-tooltip>

        <v-menu activator="#alert-menu-activator"
                max-width="500">
            <v-list lines="three"
                    v-if="hasAlerts">
                <v-list-item :href="alert.link"
                             :key="`alert_${alert.id}`"
                             target="_blank"
                             v-for="alert in alerts">
                    <template #prepend>
                        <v-avatar>
                            <v-icon :color="alert.type">{{ icons[alert.type] }}</v-icon>
                        </v-avatar>
                    </template>
                    <template #title>
                        <span :class="`text-${alert.type}`">{{ t('nptb_alertMenu_createdAt', { date: d(alert.createdAt.toDate()) }) }}</span>
                    </template>
                    <template #subtitle>{{ alert.message }}</template>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
    
</template>

<script setup>
import { onMounted, onUnmounted, defineProps, ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiAlertOctagonOutline, mdiAlertOutline, mdiBellOutline, mdiBellRing, mdiInformationOutline } from '@mdi/js'

import { findAllAlertsAsync } from '../services/repositories/alertsRepository'

const { t, d } = useI18n()

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false
    },
    buId: {
        type: Number,
        default: 1
    }
})

const icons = ref({
   noAlerts: mdiBellOutline,
   alerts: mdiBellRing,
   info: mdiInformationOutline,
   warning: mdiAlertOutline,
   error: mdiAlertOctagonOutline
})

const hasAlerts = computed(() => alerts.value.length > 0)

const isLoading = ref(true)
const isDisabled = computed(() => props.isDisabled || isLoading.value)

const badgeColor = computed(() => {
    let color = 'info'
    color = alerts.value.find(alert => alert.type === 'warning')?.type || color
    return alerts.value.find(alert => alert.type === 'error')?.type || color
})

const alerts = ref([])

let alertsSubscriber = () => ({})

async function getAlerts() {
    isLoading.value = true

    alertsSubscriber()

    alertsSubscriber = await findAllAlertsAsync(
        props.buId, 
        a => {
            isLoading.value = false
            alerts.value = a
        })
}

onMounted( () => {
    getAlerts()
})

onUnmounted( () => {
    alertsSubscriber()
})

watch(() => props.buId, () => {
    getAlerts()
})
</script>