/**
 * Store of the user.
 */
export const store = {
  namespaced: true,
  state: () => ({
    profile: undefined
  }),
  mutations: {
    setProfile (state, profile) {
      state.profile = profile
    }
  },
  actions: {
    setProfile({ commit }, profile) {
      commit('setProfile', profile)
    }
  }
}
