import { createApp } from 'vue'

import { initializeFirebase } from './registerFirebase'
import { initializeErrorReporting } from './registerErrorReporting'
import { initializeServiceWorker } from './registerServiceWorker'

import App from './App.vue'

import router from './registerRouter'
import store from './registerStore'
import i18n from './registerI18n'
import theming from './registerTheming'

initializeFirebase()

if (process.env.NODE_ENV === 'production') {
  initializeErrorReporting()
  initializeServiceWorker()
}

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(theming)
  .mount('#app')