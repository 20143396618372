<template>
  <v-main class="app-main"
          id="app-main">
    <NptbLoader v-if="isLoading" />
    <router-view />
  </v-main>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import NptbLoader from '@/components/NptbLoader'

const store = useStore()

const isLoading = computed(() => { return store.state.app.isLoading })
</script>